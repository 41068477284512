import React from 'react'

import Layout from 'components/Layout'
import { Container } from 'components/Container'
import allCandidatesData from '../../content/all-candidates-videos.yml'
import { Link } from 'gatsby'
import armand_hurford_all_candidates from '../images/armand_hurford_all_candidates.jpg'

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

const allCandidatesTemplate = (props) => {
  const { pageContext } = props
  const { description, startTime } = pageContext

  const chunkLength = allCandidatesData.length / 3
  const questions = sliceIntoChunks(allCandidatesData, chunkLength)

  return (
    <Layout>
      <Container>
        <div>
          <div className="mb-5">
            <h1 className="font-display text-xl leading-7 text-slate-900">
              Squamish Chamber of Commerce All Candidates
            </h1>
            <div className="text-slate-500 text-sm">October 3rd, 2022</div>
          </div>

          <div className="text-3xl mb-5">{description}</div>

          <iframe
            className="w-full"
            style={{ 'aspect-ratio': '16/9' }}
            src={`https://www.youtube-nocookie.com/embed/f8R9r46Ew-o?start=${startTime}`}
            frameborder="0"
            allow="autoplay;"
            allowfullscreen
          />
        </div>{' '}
        <div className="mt-10 bg-gray-50 overflow-hidden rounded-lg">
          <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
            <h3 className="font-display text-xl font-medium text-slate-900">
              Question Quick Links
            </h3>
            <ul
              role="list"
              className="mx-auto mt-10 grid grid-cols-1 gap-x-8 lg:max-w-none lg:grid-cols-3"
            >
              {questions.map((col, index1) => {
                return (
                  <div>
                    {col.map((row, index2) => (
                      <div
                        key={`question-${index1}-${index2}`}
                        className="mb-5"
                      >
                        <Link
                          className="text-base leading-7 underline text-gray-600"
                          to={row.path}
                        >
                          {row.description}
                        </Link>
                      </div>
                    ))}
                  </div>
                )
              })}
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
export default allCandidatesTemplate

export const Head = ({ location, _params, _data, pageContext }) => {
  const { description } = pageContext
  return (
    <>
      <title>Armand Hurford for Squamish Mayor</title>
      <meta name="description" content={description} />
      <meta
        property="og:title"
        content="Squamish Chamber Of Commerce: All Candidates 2022"
      />
      <meta
        property="og:url"
        content={`https://armandhurford.com${location.pathname}`}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={armand_hurford_all_candidates} />
    </>
  )
}
